<template>
  <v-container>
    <v-card
    class="d-flex flex-column mx-auto"
    width="98%"
    min-width="330px"
    max-width="530px"
      >

      <v-card-title class="justify-center">
        <div id='titleColor'>
          {{cTitle}}
        </div>
      </v-card-title>

      <div class="text-center">

      <v-dialog
        v-model="loadingD"
        hide-overlay
        persistent
        width="300"
        id="loadingDiv"
      >
        <v-card
          color="primary"
          dark
        >
          <v-card-text>
            Loading...
            <v-progress-linear
              indeterminate
              color="white"
              class="mb-0"
            ></v-progress-linear>
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>

      <v-card-text>

      <div :style="cssProps">
      <div class="outerContainer">
          <div id="container">
            <div id="mapContainer"></div>
            <v-img
            :src="require('../assets/' + arrowImage + '.png')"
            id="arrow"
            height="100%"
            width="100%"
            />
            <v-img
            :src="require('../assets/' + lineImage + '.png')"
            id="line"
            height="100%"
            width="100%"
            />
          </div>
        </div>
      </div>


      </v-card-text>

      <v-card-actions>
        <v-btn
          to="/"
          outlined
          color="#000000"
        >
        <v-icon>
          mdi-compass-outline
        </v-icon>
        &nbsp;Compass
        </v-btn>

        <v-spacer></v-spacer>

        <div v-if="cStatus == 'start'">

        <v-btn
        @click="getLocation"
        color="#C8E6C9"
        >
        Start Map View
        </v-btn>

      </div>

      <div v-else-if="cStatus == 'geoError'">

        <v-btn
        text
        @click="stopLocation"
        outlined
        color="#F44336"
        >
        Try Again
      </v-btn>

      </div>

      <div v-else-if="cStatus == 'orientationSuccess'">
        <v-btn
        text
        @click="stopLocation"
        outlined
        color="#F44336"
        >
        Stop Map View
        </v-btn>

      </div>

    </v-card-actions>

      <v-card-text>

        <div class="cInst">

          <div v-if="cStatus == 'start'">
            1. Green circle is Har Habayit<br>
            2. Click 'Start Map View' button<br>
            3. Red circle is your location<br>
            4. Black line points to current bearing<br>
            5. Turn until bearing and orientation align<br>
            6. Daven facing direction of green arrow
          </div>

          <div v-else-if="cStatus == 'getLocationE'">

            <div v-if="errCode == 'gnf1'">
            This device does not have GeoLocation services.
            <br>Please try again using a different device.
            </div>

            <div v-if="errCode == 'udp1'">
            You need to "Allow" access to your Motion and Orientation for the app to work.
            <br>Please try again
            </div>

          </div>

          <div v-else-if="cStatus == 'geoError'">
            <div v-if="errCode == '1'">
            1. Make sure that your device's Location services are turned on.<br><br>
            2. Select "Allow" access to your current location if requested.
            <div v-if="tIOS">
            <br><br>
            If that does not solve the problem, please try:
            <br><br>
            1. On your iPhone go to Settings > Privacy > Location Services.<br>
            2. Select Safari Websites or Chrome (whichever you are using)<br>
            3. Set 'Location Access' to: While Using the App
            </div>
            </div>

            <div v-if="errCode == '2'">
            Err Message: {{errMessage}}
            <br><br>
            Please try again
            </div>

            <div v-if="errCode == '3'">
            Err Message: {{errMessage}}
            <br><br>
            Please try again
            </div>
          </div>

          <div v-else-if="cStatus == 'orientationSuccess'">
            Bearing to Har Habayit: {{bearing}}<br>
            Current Orientation: {{orientation}}<br>
            Distance to Har Habayit: {{distance}}km<br><br>
            To preserve battery click 'Stop Map View' when done
          </div>

        </div>

      </v-card-text>

  </v-card>
  </v-container >
</template>

<script>
import "leaflet/dist/leaflet.css";
import L from "leaflet";

export default {
  name: 'GeoLocation',
  components: {

  },
  data () {
    return {
      loadingD: false,
      iHeight: '1',
      viewWidth: null,
      viewWidthA: null,
      harHabayit: [31.7785, 35.2341],
      cStatus: 'start',
      cTitle: 'Which Way to Pray?',
      arrowImage: 'clear',
      lineImage: 'line1',
      tResult: '',
      latitude: '',
      longitude: '',
      bearing: '',
      orientation: '',
      distance: '',
      tIOS: '',
      fLocation: '',
      fLocation2: '',
      errCode: '',
      errMessage: ''
    }
  },
  beforeCreate() {
  },
  created () {
    var ua = navigator.userAgent
    var isMobileV = /Android|webOS|iPhone|iPad|iPod/i.test(ua)
    if(!isMobileV){
      this.$router.push('/harHabayitDesktop')
    }
    else {
      this.viewWidth = Math.floor((window.innerWidth))
      this.viewWidthA = Math.floor(this.viewWidth * .9)
      if (this.viewWidthA < 315) {
        this.iHeight = '0'
      } else if (this.viewWidthA < 401) {
        this.iHeight = '1'
      } else if (this.viewWidthA < 501 ) {
        this.iHeight = '2'
      } else if (this.viewWidthA < 601 ) {
        this.iHeight = '3'
      } else {
        this.iHeight = '4'
      }
    }

  },
  beforeMount() {

  },
  mounted() {
  this.makeMap()
  },
  beforeUpdate() {

  },
  updated() {

  },
  computed: {
    widthHeight: function () {

      if (this.iHeight == '0') return '250px'
      if (this.iHeight == '1') return '280px'
      if (this.iHeight == '2') return '330px'
      if (this.iHeight == '3') return '380px'
      if (this.iHeight == '4') return '430px'
      return "280px"
    },
      cssProps() {
        return {
        '--width-height': this.widthHeight
        }
      }
  },
  methods: {
    getLocation() {
      this.fLocation = 'Navigator Geolocation Error'
      this.fLocation2 = ''
      this.errCode = ''
      this.errMessage = ''

      this.tIOS = (/iPad|iPhone|iPod/.test(navigator.platform) ||
      (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)) &&
      !window.MSStream

      if (!navigator.geolocation) {
        this.cStatus = 'getLocationE'
        this.fLocation2 = 'Geolocation Not Found'
        this.errCode = 'gnf1'
      } else {

        if (this.tIOS) {
          DeviceOrientationEvent.requestPermission()
          .then((response) => {
            if (response === "granted") {
              navigator.geolocation.getCurrentPosition(this.success, this.error)
          } else {
            this.cStatus = 'getLocationE'
            this.fLocation2 = 'User Denied Permission'
            this.errCode = 'udp1'
          }
        })
        } else {
          this.loadingD = true
          navigator.geolocation.getCurrentPosition(this.success, this.error);
      }
    }
  },
    handleOrientation(e) {
      this.cStatus = 'orientationSuccess'
      if (this.tIOS) {
        this.orientation = parseFloat((e.webkitCompassHeading)).toFixed(1)
      }
      else {
        this.orientation =  parseFloat((360 - e.alpha)).toFixed(1)
      }

      var arrow = document.getElementById('arrow')
      var line = document.getElementById('line')
      var cDirect = this.bearing - this.orientation

      arrow.style.Transform = 'rotate(' + cDirect + 'deg)';
      arrow.style.WebkitTransform = 'rotate('+ cDirect + 'deg)';
      arrow.style.MozTransform = 'rotate(-' + cDirect + 'deg)';
      line.style.Transform = 'rotate(' + this.orientation + 'deg)';
      line.style.WebkitTransform = 'rotate('+ this.orientation + 'deg)';
      line.style.MozTransform = 'rotate(-' + this.orientation + 'deg)';

        if ( Math.abs(this.bearing - this.orientation) < 2) {
          this.arrowImage = 'arrowUpG'
          document.getElementById('arrow').style.visibility = 'visible'
          this.cTitle = 'Daven this Direction'
          document.getElementById('titleColor').style.color = '#4CAF50'
        } else {
          this.arrowImage = 'clear'
          document.getElementById('arrow').style.visibility = 'hidden'
          this.cTitle = 'Rotate Device'
          document.getElementById('titleColor').style.color = '#F44336'
        }
      },
      radians(n) {
        return n * (Math.PI / 180);
      },
      degrees(n) {
        return n * (180 / Math.PI);
      },
      success(position) {
        this.loadingD = true
        this.cStatus = 'geoSuccess'
        this.latitude = position.coords.latitude
        this.longitude = position.coords.longitude

        var startLat = this.radians(this.latitude);
        var startLong = this.radians(this.longitude);
        var endLat = this.radians(this.harHabayit[0]);
        var endLong = this.radians(this.harHabayit[1]);
        var latlng = [this.latitude, this.longitude]

        var tDistance = this.getDistanceFromLatLonInKm(this.harHabayit[0], this.harHabayit[1], this.latitude, this.longitude)
        this.distance = Math.floor(tDistance)

        var dLong = endLong - startLong;

        var dPhi = Math.log(Math.tan(endLat/2.0+Math.PI/4.0)/Math.tan(startLat/2.0+Math.PI/4.0));

        if (Math.abs(dLong) > Math.PI) {
          if (dLong > 0.0)
             dLong = -(2.0 * Math.PI - dLong);
          else
             dLong = (2.0 * Math.PI + dLong);
           }

        var brng =  (this.degrees(Math.atan2(dLong, dPhi)) + 360.0) % 360.0;
        this.bearing = parseFloat((brng)).toFixed(1)

        L.circleMarker(latlng, {
          radius: 5,
          color: "#F44336",
        }).addTo(this.map);


        var featureGroup = new L.FeatureGroup([
            new L.Marker(this.harHabayit),
            new L.Marker(latlng)
        ]);

        var zoom = this.map.getBoundsZoom(featureGroup.getBounds())

        zoom = zoom - 1
        this.loadingD = false
        this.map.flyTo(latlng, zoom)

        if (this.tIOS) {
          window.addEventListener('deviceorientation', this.handleOrientation, false)
        } else {
          window.addEventListener('deviceorientationabsolute', this.handleOrientation)
        }

        document.getElementById('line').style.visibility = 'visible'
      },
      getDistanceFromLatLonInKm(lat1, lon1, lat2, lon2) {
        var R = 6371; // Radius of the earth in km
        var dLat = this.deg2rad(lat2-lat1);  // deg2rad below
        var dLon = this.deg2rad(lon2-lon1);
        var a =
        Math.sin(dLat/2) * Math.sin(dLat/2) +
        Math.cos(this.deg2rad(lat1)) * Math.cos(this.deg2rad(lat2)) *
        Math.sin(dLon/2) * Math.sin(dLon/2)

        var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
        var d = R * c; // Distance in km
        return d;
      },
      deg2rad(deg) {
        return deg * (Math.PI/180)
      },
      error(err) {
        this.loadingD = false
        this.cStatus = 'geoError'
        this.fLocation = 'Orientation Error'
        this.errCode = err.code
        this.errMessage = err.message
      },
      makeMap() {

        this.map = L.map("mapContainer", {
            center: this.harHabayit,
            zoom: 18,
        });

        this.map.attributionControl.setPrefix('<a href=https://leafletjs.com/ target=_blank>Leaflet</a>')

        L.tileLayer("https://{s}.tile.osm.org/{z}/{x}/{y}.png", {
            attribution:
                '&copy; <a href="http://osm.org/copyright" target="_blank">OpenStreetMap</a> contributors'
        }).addTo(this.map);

        L.circleMarker(this.harHabayit, {
          radius: 15,
          color: "#4CAF50",
        }).addTo(this.map);
      },
    stopLocation() {
      if (this.tIOS) {
        window.removeEventListener('deviceorientation', this.handleOrientation, false)
      }
      else {
        window.removeEventListener('deviceorientationabsolute', this.handleOrientation)
      }

      this.map.remove();
      this.makeMap();
      document.getElementById('titleColor').style.color = '#000000'
      this.arrowImage = 'clear'
      this.cTitle = 'Which Way to Pray?'
      this.lineImage = 'line1'
      this.tResult = ''
      this.latitude = ''
      this.longitude = ''
      this.bearing = ''
      this.orientation = ''
      this.cStatus = 'start'
      this.distance = ''
      this.tIOS = ''

      document.getElementById('arrow').style.visibility = 'hidden'
      document.getElementById('line').style.visibility = 'hidden'

    }
  },
  beforeDestroy() {
      if (this.map) {
          this.map.remove();
      }
  }
}
</script>
<style scoped>

#titleColor {
  color: #000000;
}

.cInst {
  font-size: 16px;
  font-weight: 500;
  height: 150px;
  color: #000000;
}

.outerContainer {
  height: 100%;
  width: 100%;
  text-align: center;
}

#container {
  display: inline-block;
  margin: 0 auto;
  width: var(--width-height);
  height: var(--width-height);
  position: relative;
}

#container > #mapContainer,
#container > #line,
#container > #arrow {
  position:absolute;
  left:0;
  top:0;
}

#mapContainer {
  width: 100%;
  height: 100%;
  border-style: solid;
  border-width: thin;
}

#loadingDiv {
  z-index: 999998 !important;
}

#line {
  z-index: 999998 !important;
  visibility: hidden;
}

#arrow {
  z-index: 999998 !important;
  visibility: hidden;
}

</style>
