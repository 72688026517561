<template>
  <v-app>
    <div align=right class='bsd'>
      בס״ד
      </div>
    <div v-if="updateExists">
      <v-btn
        block
        @click="refreshApp"
      >
        Update App
      </v-btn>
    </div>
    <v-main>
      <router-view/>

      <v-col
    class="text-center"
    cols="12"
  >
  <div class="text-center">

  <v-dialog
    v-model="loadingD"
    hide-overlay
    persistent
    width="300"
    id="loadingDiv"
  >
    <v-card
      color="primary"
      dark
    >
      <v-card-text>
        Loading...
        <v-progress-linear
          indeterminate
          color="white"
          class="mb-0"
        ></v-progress-linear>
      </v-card-text>
    </v-card>
  </v-dialog>
</div>

  <div class=zmanBut>

      <v-btn
        outlined
        @click="getLocation()"
        color="#1B5E20"
      >
      <v-icon>
        mdi-clock-outline
      </v-icon>
        &nbsp; Zmanim for your Location
      </v-btn>

    </div>
  </v-col>

      <v-dialog v-model="zmanVisible" id="zmanDiv">

          <v-card
          class="d-flex flex-column mx-auto"
          width="98%"
          min-width="330px"
          max-width="530px"
            >
<div align=right>
            <v-btn
            outlined
            text
            @click="zmanVisible = false"
            >
          X
        </v-btn>
  </div>

            <v-card-title class="justify-center">
                What Time to Pray
            </v-card-title>

      <div v-if='errCode'>

        <v-card-text>

        <div class="cInst">

        Err Code: {{errCode}}
        <br><br>
        Location: {{fLocation}}<br>
        <div v-if="fLocation2">
        Location 2: {{fLocation2}}<br>
        </div>


        <div v-if="errCode == 'gnf1'">
        <br>
        This device does not have GeoLocation services.
        <br>Please try again using a different device.
        </div>

        <div v-if="errCode == 'udp1'">
        <br>
        You need to "Allow" access to your Motion and Orientation for the app to work.
        <br>Please try again
        </div>

        <div v-if="errCode == '1'">
        <br>
        1. Make sure that your device's Location services are turned on.<br><br>
        2. Select "Allow" access to your current location if requested.
        <div v-if="tIOS">
        <br><br>
        If that does not solve the problem, please try:
        <br><br>
        1. On your iPhone go to Settings > Privacy > Location Services.<br>
        2. Select Safari Websites or Chrome (whichever you are using)<br>
        3. Set 'Location Access' to: While Using the App
        </div>
        </div>

        <div v-if="errCode == '2'">
        <br>
        Err Message: {{errMessage}}
        <br><br>
        Please try again
        </div>

        <div v-if="errCode == '3'">
        <br>
        Err Message: {{errMessage}}
        <br><br>
        Please try again
        </div>

        </div>

      </v-card-text>

      </div>
      <div v-else>

      <v-card-text>

      <div class="cInst">

      <div align=center>
      {{tDaTi}}<br>
      Locality: <b>{{locality}}</b>
      <br><br>
      <v-simple-table dense>
          <tbody>
      <tr><td>Chatzot (night)</td><td>{{chatzotNight}}</td></tr>
      <tr><td>Alot HaShachar</td><td>{{alotHaShachar}}</td></tr>
      <tr><td>Misheyakir</td><td>{{misheyakir}}</td></tr>
      <tr><td>Misheyakir Machmir</td><td>{{misheyakirMachmir}}</td></tr>
      <tr><td>Dawn</td><td>{{dawn}}</td></tr>
      <tr><td>Sunrise</td><td>{{sunrise}}</td></tr>
      <tr><td>Sof Zman Shma Gra</td><td>{{sofZmanShmaMGA}}</td></tr>
      <tr><td>Sof Zman Shma</td><td>{{sofZmanShma}}</td></tr>
      <tr><td>Sof Zman Tfilla Gra</td><td>{{sofZmanTfillaMGA}}</td></tr>
      <tr><td>Sof Zman Tfilla</td><td>{{sofZmanTfilla}}</td></tr>
      <tr><td>Chatzot (midday)</td><td>{{chatzot}}</td></tr>
      <tr><td>Mincha Gedola</td><td>{{minchaGedola}}</td></tr>
      <tr><td>Mincha Ketana</td><td>{{minchaKetana}}</td></tr>
      <tr><td>Plag HaMincha</td><td>{{plagHaMincha}}</td></tr>
      <tr><td>Sunset</td><td>{{sunset}}</td></tr>
      <tr><td>Dusk</td><td>{{dusk}}</td></tr>
      <tr><td>Tzeit (70-83deg)</td><td>{{tzeit7083deg}}</td></tr>
      <tr><td>Tzeit (85deg)</td><td>{{tzeit85deg}}</td></tr>
      <tr><td>Tzeit (42min)</td><td>{{tzeit42min}}</td></tr>
      <tr><td>Tzeit (50min)</td><td>{{tzeit50min}}</td></tr>
      <tr><td>Tzeit (72min)</td><td>{{tzeit72min}}</td></tr>
    </tbody>
</v-simple-table>
    </div>
      </div>

      </v-card-text>

      <v-card-text>
        <div class="disc">
          zmanim provided by hebcal.com<br>
          locality provided by bigdatacloud.net
        </div>

      </v-card-text>

      </div>

            <v-card-actions class="justify-end">
              <v-btn
                text
                @click="zmanVisible = false"
              >Close</v-btn>
            </v-card-actions>
          </v-card>

      </v-dialog>
    </v-main>

      <v-footer
        padless
         style="background-color: white;"
      >
  <v-col
cols="10"
>
<v-icon
small
>
mdi-copyright
</v-icon>
Har Habayit Compass

</v-col>
<v-col
cols="2"
class="text-right"
>
v2.8
</v-col>

      </v-footer>

  </v-app>
</template>

<script>
import update from './mixins/update'

export default {
  data() {
    return {
      loadingD: false,
      zmanVisible: false,
      tIOS: '',
      fLocation: '',
      fLocation2: '',
      errCode: '',
      errMessage: '',
      latitude: '',
      longitude: '',
      locality: '',
      tZ: '',
      fZmanim: '',
      tDaTi: '',
      chatzotNight: '',
      alotHaShachar: '',
      misheyakir: '',
      misheyakirMachmir: '',
      dawn: '',
      sunrise: '',
      sofZmanShma: '',
      sofZmanShmaMGA: '',
      sofZmanTfilla: '',
      sofZmanTfillaMGA: '',
      chatzot: '',
      minchaGedola: '',
      minchaKetana: '',
      plagHaMincha: '',
      sunset: '',
      dusk: '',
      tzeit7083deg: '',
      tzeit85deg: '',
      tzeit42min: '',
      tzeit50min: '',
      tzeit72min: ''
    }
  },
  mixins: [update],
  created() {

  },
  methods: {
    getLocation() {
      this.fLocation = 'Navigator Geolocation Error'
      this.fLocation2 = ''
      this.errCode = ''
      this.errMessage = ''

      this.tIOS = (/iPad|iPhone|iPod/.test(navigator.platform) ||
      (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)) &&
      !window.MSStream

        if (!navigator.geolocation) {
          this.fLocation2 = 'Geolocation Not Found'
          this.errCode = 'gnf1'
          this.zmanVisible = true
        } else {

          if (this.tIOS) {
            DeviceOrientationEvent.requestPermission()
            .then((response) => {
              if (response === "granted") {
                navigator.geolocation.getCurrentPosition(this.success, this.error)
            } else {
              this.fLocation2 = 'User Denied Permission'
              this.errCode = 'udp1'
              this.zmanVisible = true
            }
          })
          }
          else {
              this.loadingD = true
              navigator.geolocation.getCurrentPosition(this.success, this.error)
        }
      }
    },
    success(position) {
      this.loadingD = true
      this.latitude = position.coords.latitude
      this.longitude = position.coords.longitude
      this.getLocInfo()
      this.getZmanim()
    },
    async getLocInfo(){
        const fetchPath = 'https://api.bigdatacloud.net/data/reverse-geocode-client?latitude=' + this.latitude + '&longitude=' + this.longitude
        const headers = { "Accept": "application/json" };
        const res = await fetch(fetchPath, { headers });
        const data = await res.json()
        this.locality = data.locality
      },
      async getZmanim(){
        this.tZ = Intl.DateTimeFormat().resolvedOptions().timeZone
        const fetchPath = 'https://www.hebcal.com/zmanim?cfg=json&latitude=' + this.latitude + '&longitude=' + this.longitude + '&tzid=' + this.tZ + '&sec=1'
        const headers = { "Accept": "application/json" };
        const res = await fetch(fetchPath, { headers });
        const data = await res.json();
        this.fZmanim = data
        var MONTHS = ["January","February","March","April","May","June","July","August","September","October","November","December"];
        var myDate, myFormatDate
        var date_str = new Date(data.date).toLocaleDateString()
        const weekday = ["Sunday","Monday","Tuesday","Wednesday","Thursday","Friday","Saturday"];
        const tmpDay = new Date(date_str)
        var tmpDay2 = weekday[tmpDay.getDay()]
        var t = date_str.split("/");
        if(t[2]) {
          myDate = new Date(t[2], t[0] - 1, t[1]);
          myFormatDate = tmpDay2 + ", " + MONTHS[myDate.getMonth()] + ' ' + myDate.getDate() + ", " + myDate.getFullYear();
        } else {
          myDate = new Date(new Date().getFullYear(), t[0] - 1, t[1]);
          myFormatDate = tmpDay2 + ", " + MONTHS[myDate.getMonth()] + ' ' + myDate.getDate();
        }
        this.tDaTi = myFormatDate
        this.chatzotNight = new Date(data.times.chatzotNight).toLocaleTimeString()
        this.alotHaShachar = new Date(data.times.alotHaShachar).toLocaleTimeString()
        this.misheyakir = new Date(data.times.misheyakir).toLocaleTimeString()
        this.misheyakirMachmir = new Date(data.times.misheyakirMachmir).toLocaleTimeString()
        this.dawn = new Date(data.times.dawn).toLocaleTimeString()
        this.sunrise = new Date(data.times.sunrise).toLocaleTimeString()
        this.sofZmanShma = new Date(data.times.sofZmanShma).toLocaleTimeString()
        this.sofZmanShmaMGA = new Date(data.times.sofZmanShmaMGA).toLocaleTimeString()
        this.sofZmanTfilla = new Date(data.times.sofZmanTfilla).toLocaleTimeString()
        this.sofZmanTfillaMGA = new Date(data.times.sofZmanTfillaMGA).toLocaleTimeString()
        this.chatzot = new Date(data.times.chatzot).toLocaleTimeString()
        this.minchaGedola = new Date(data.times.minchaGedola).toLocaleTimeString()
        this.minchaKetana = new Date(data.times.minchaKetana).toLocaleTimeString()
        this.plagHaMincha = new Date(data.times.plagHaMincha).toLocaleTimeString()
        this.sunset = new Date(data.times.sunset).toLocaleTimeString()
        this.dusk = new Date(data.times.dusk).toLocaleTimeString()
        this.tzeit7083deg = new Date(data.times.tzeit7083deg).toLocaleTimeString()
        this.tzeit85deg = new Date(data.times.tzeit85deg).toLocaleTimeString()
        this.tzeit42min = new Date(data.times.tzeit42min).toLocaleTimeString()
        this.tzeit50min = new Date(data.times.tzeit50min).toLocaleTimeString()
        this.tzeit72min = new Date(data.times.tzeit72min).toLocaleTimeString()
        this.loadingD = false
        this.zmanVisible = true
      },
    error(err) {
      this.fLocation = 'Orientation Error'
      this.errCode = err.code
      this.errMessage = err.message
      this.loadingD = false
      this.zmanVisible = true
    }
  }
}
</script>

<style scoped>
.cInst {
  font-size: 16px;
  font-weight: 500;
  color: #000000;
}

.bsd {
  font-size: 16px;
  font-weight: 500;
  padding: 10px;
}

.zmanBut {
  padding-top: 10px;
}

#loadingDiv {
  z-index: 999998 !important;
}

#zmanDiv {
  border-spacing: 10px;
  z-index: 999998 !important;
}

</style>
