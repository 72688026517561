import Vue from 'vue'
import VueRouter from 'vue-router'

import Home from '../components/HarHabayit12.vue'
import HarHabayitDesktop from '../components/HarHabayitDesktop2.vue'
import MapView from '../components/MapView5.vue'

Vue.use(VueRouter)

const routes = [
  { path: '/', component: Home },
  { path: '/harHabayitDesktop', component: HarHabayitDesktop },
  { path: '/mapView', component: MapView }
]

const router = new VueRouter({
  routes
})

router.beforeResolve((to, from, next) => {
  next()
})

export default router
