<template>
  <v-container>

    <v-card
    class="d-flex flex-column mx-auto"
    width="98%"
    min-width="330px"
    max-width="530px"
      >

      <v-card-title class="justify-center">
        <div id='titleColor'>
          {{cTitle}}
        </div>
      </v-card-title>

      <div class="text-center">

      <v-dialog
        v-model="loadingD"
        hide-overlay
        persistent
        width="300"
      >
        <v-card
          color="primary"
          dark
        >
          <v-card-text>
            Loading...
            <v-progress-linear
              indeterminate
              color="white"
              class="mb-0"
            ></v-progress-linear>
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>

      <v-card-text>

      <div :style="cssProps">
      <div class="outerContainer">

        <div class="parent">

          <v-img
          :src="require('../assets/diana-1-webp-512.webp')"
          id="harHabayit"
          height="100%"
          width="100%"
          class="image1"
          />

          <v-img
          :src="require('../assets/' + arrowImage + '.png')"
          id="arrow"
          height="100%"
          width="100%"
          class="image2"
          />

          <div class="diana">
            <a class="dLink" href="https://www.dianakurz.com/about" target="_blank">&copy; Diana Kurz</a> &nbsp;
          </div>

        </div>

      </div>
    </div>

      </v-card-text>

      <v-card-actions>
        <v-btn
          to="/mapView"
          outlined
          color="#000000"
        >
        <v-icon>
          mdi-cellphone-marker
        </v-icon>
        &nbsp;Map View
        </v-btn>

        <v-spacer></v-spacer>

        <div v-if="cStatus == 'start'">

        <v-btn
        @click="getLocation"
        color="#C8E6C9"
        >
        Start Compass
        </v-btn>

      </div>

      <div v-else-if="cStatus == 'geoError'">

        <v-btn
        text
        @click="stopLocation"
        outlined
        color="#F44336"
        >
        Try Again
      </v-btn>

      </div>

      <div v-else-if="cStatus == 'orientationSuccess'">
        <v-btn
        text
        @click="stopLocation"
        outlined
        color="#F44336"
        >
        Stop Compass
        </v-btn>

      </div>

    </v-card-actions>

      <v-card-text>

      <div class="cInst">

        <div v-if="cStatus == 'start'">
          1. Place your device on a flat surface<br>
          2. Click 'Start Compass' button<br>
          3. Rotate device until arrow turns green<br>
          4. Daven facing direction of green arrow

        </div>

        <div v-else-if="cStatus == 'getLocationE'">

          <div v-if="errCode == 'gnf1'">
          This device does not have GeoLocation services.
          <br>Please try again using a different device.
          </div>

          <div v-if="errCode == 'udp1'">
          You need to "Allow" access to your Motion and Orientation for the app to work.
          <br>Please try again
          </div>

        </div>

        <div v-else-if="cStatus == 'geoError'">
          <div v-if="errCode == '1'">
          1. Make sure that your device's Location services are turned on.<br><br>
          2. Select "Allow" access to your current location if requested.
          <div v-if="tIOS">
          <br><br>
          If that does not solve the problem, please try:
          <br><br>
          1. On your iPhone go to Settings > Privacy > Location Services.<br>
          2. Select Safari Websites or Chrome (whichever you are using)<br>
          3. Set 'Location Access' to: While Using the App
          </div>
          </div>

          <div v-if="errCode == '2'">
          Err Message: {{errMessage}}
          <br><br>
          Please try again
          </div>

          <div v-if="errCode == '3'">
          Err Message: {{errMessage}}
          <br><br>
          Please try again
          </div>
        </div>

        <div v-else-if="cStatus == 'orientationSuccess'">
          Bearing to Har Habayit: {{bearing}}<br>
          Current Orientation: {{orientation}}<br>
          Distance to Har Habayit: {{distance}}km<br><br>
          To preserve battery click 'Stop Compass' when done.
        </div>

      </div>

      </v-card-text>

  </v-card>

  </v-container >
</template>

<script>

export default {
  name: 'GeoLocation',
  components: {

  },
  data () {
    return {
      loadingD: false,
      iHeight: '1',
      viewWidth: null,
      viewWidthA: null,
      harHabayit: [31.7785, 35.2341],
      cStatus: 'start',
      tResult: '',
      tError: '',
      cTitle: 'Which Way to Pray?',
      arrowImage: 'clear',
      latitude: '',
      latitudeS: '',
      longitude: '',
      longitudeS: '',
      bearing: '',
      orientation: '',
      distance: '',
      tIOS: '',
      fLocation: '',
      fLocation2: '',
      errCode: '',
      errMessage: ''
    }
  },
  beforeCreate() {

  },
  created () {
    var ua = navigator.userAgent
    var isMobileV = /Android|webOS|iPhone|iPad|iPod/i.test(ua)
    if(!isMobileV){
      this.$router.push('/harHabayitDesktop')
    }
    else {
      this.viewWidth = Math.floor((window.innerWidth))
      this.viewWidthA = Math.floor(this.viewWidth * .9)
      if (this.viewWidthA < 315) {
        this.iHeight = '0'
      } else if (this.viewWidthA < 401) {
        this.iHeight = '1'
      } else if (this.viewWidthA < 501 ) {
        this.iHeight = '2'
      } else if (this.viewWidthA < 601 ) {
        this.iHeight = '3'
      } else {
        this.iHeight = '4'
      }
    }
  },
  beforeMount() {

  },
  mounted() {

  },
  beforeUpdate() {

  },
  updated() {

  },
  computed: {
    checkMobile() {
      var ua = navigator.userAgent
      var isMobileV = /Android|webOS|iPhone|iPad|iPod/i.test(ua)
      return isMobileV
    },
    widthHeight: function () {

      if (this.iHeight == '0') return '251px'
      if (this.iHeight == '1') return '281px'
      if (this.iHeight == '2') return '331px'
      if (this.iHeight == '3') return '381px'
      if (this.iHeight == '4') return '431px'
      return "281px"
    },
    cssProps() {
      return {
      '--width-height': this.widthHeight
      }
    }
  },
  methods: {
    getLocation() {
      this.fLocation = 'Navigator Geolocation Error'
      this.fLocation2 = ''
      this.errCode = ''
      this.errMessage = ''

      this.tIOS = (/iPad|iPhone|iPod/.test(navigator.platform) ||
      (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)) &&
      !window.MSStream

        if (!navigator.geolocation) {
          this.cStatus = 'getLocationE'
          this.fLocation2 = 'Geolocation Not Found'
          this.errCode = 'gnf1'
        } else {

          if (this.tIOS) {
            DeviceOrientationEvent.requestPermission()
            .then((response) => {
              if (response === "granted") {
                navigator.geolocation.getCurrentPosition(this.success, this.error)
            } else {
              this.cStatus = 'getLocationE'
              this.fLocation2 = 'User Denied Permission'
              this.errCode = 'udp1'
            }
          })
          } else {
        this.loadingD = true
        navigator.geolocation.getCurrentPosition(this.success, this.error);
      }
    }
  },
    handleOrientation(e) {
      this.cStatus = 'orientationSuccess'
      if (this.tIOS) {
        this.orientation = parseFloat((e.webkitCompassHeading)).toFixed(1)
      }
      else {
        this.orientation =  parseFloat((360 - e.alpha)).toFixed(1)
      }
      this.cTitle = 'Har Habayit'
      var arrow = document.getElementById('arrow')

      var cDirect = this.orientation - this.bearing

        if (cDirect < 0) {
          cDirect = cDirect + 360
          }

        arrow.style.Transform = 'rotate(' + cDirect + 'deg)';
        arrow.style.WebkitTransform = 'rotate('+ cDirect + 'deg)';
        arrow.style.MozTransform = 'rotate(-' + cDirect + 'deg)';

        if ( Math.abs(this.bearing - this.orientation) < 2) {
          this.arrowImage = 'arrowUpG'
          this.cTitle = 'Daven this Direction'
          document.getElementById('titleColor').style.color = '#4CAF50'
        } else {
          this.arrowImage = 'arrowUpR'
          this.cTitle = 'Rotate Device'
          document.getElementById('titleColor').style.color = '#F44336'
        }
      },
      radians(n) {
        return n * (Math.PI / 180);
      },
      degrees(n) {
        return n * (180 / Math.PI);
      },
      success(position) {
        this.loadingD = true
        this.cStatus = 'geoSuccess'
        this.latitude = position.coords.latitude
        this.longitude = position.coords.longitude

        // Put code here for N/S latitude and E/W longitude - also shorten to four digits
      var tempLat = this.latitude.toFixed(4)
      var tempLon = this.longitude.toFixed(4)

      if (this.latitude >= 0) {
        this.latitudeS = tempLat + 'N'
      } else {
        this.latitudeS = tempLat + 'S'
      }

      if (this.longitude >= 0) {
        this.longitudeS = tempLon + 'E'
      } else {
        this.longitudeS = tempLon + 'W'
      }



        var startLat = this.radians(this.latitude);
        var startLong = this.radians(this.longitude);
        var endLat = this.radians(this.harHabayit[0]);
        var endLong = this.radians(this.harHabayit[1]);

        var tDistance = this.getDistanceFromLatLonInKm(this.harHabayit[0], this.harHabayit[1], this.latitude, this.longitude)
        this.distance = Math.floor(tDistance)

        var dLong = endLong - startLong;

        var dPhi = Math.log(Math.tan(endLat/2.0+Math.PI/4.0)/Math.tan(startLat/2.0+Math.PI/4.0));

        if (Math.abs(dLong) > Math.PI) {
          if (dLong > 0.0)
             dLong = -(2.0 * Math.PI - dLong);
          else
             dLong = (2.0 * Math.PI + dLong);
           }

        var brng =  (this.degrees(Math.atan2(dLong, dPhi)) + 360.0) % 360.0;
        this.bearing = parseFloat((brng)).toFixed(1)

        this.loadingD = false

        if (this.checkMobile) {

        if (this.tIOS) {
          window.addEventListener('deviceorientation', this.handleOrientation, false)
        } else {
          window.addEventListener('deviceorientationabsolute', this.handleOrientation)
        }

        document.getElementById('arrow').style.visibility = 'visible'

      }
      },
      getDistanceFromLatLonInKm(lat1, lon1, lat2, lon2) {
        var R = 6371; // Radius of the earth in km
        var dLat = this.deg2rad(lat2-lat1);  // deg2rad below
        var dLon = this.deg2rad(lon2-lon1);
        var a =
        Math.sin(dLat/2) * Math.sin(dLat/2) +
        Math.cos(this.deg2rad(lat1)) * Math.cos(this.deg2rad(lat2)) *
        Math.sin(dLon/2) * Math.sin(dLon/2)

        var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
        var d = R * c; // Distance in km
        return d;
      },
      deg2rad(deg) {
        return deg * (Math.PI/180)
      },
      error(err) {
        this.loadingD = false
        this.cStatus = 'geoError'
        this.fLocation = 'Orientation Error'
        this.errCode = err.code
        this.errMessage = err.message
      },
      stopLocation() {

        if (!this.checkMobile) {
          this.tResult = ''
          this.latitude = ''
          this.longitude = ''
          this.latitudeS = ''
          this.longitudeS = ''
          this.bearing = ''
          this.orientation = ''
          this.cStatus = 'start'
          this.distance = ''
          this.tIOS = ''
        }

        else {

        if (this.tIOS) {
          window.removeEventListener('deviceorientation', this.handleOrientation, false)
        }
        else {
          window.removeEventListener('deviceorientationabsolute', this.handleOrientation)
        }
        document.getElementById('titleColor').style.color = '#000000'
        this.cTitle = 'Which Way to Pray?'
        this.arrowImage = 'clear'
        this.tResult = ''
        this.latitude = ''
        this.longitude = ''
        this.bearing = ''
        this.orientation = ''
        this.cStatus = 'start'
        this.distance = ''
        this.tIOS = ''

        var arrow = document.getElementById('arrow')
        arrow.style.visibility = 'hidden'
        var cDirect = this.bearing - this.orientation

          if (cDirect < 0) {
            cDirect = cDirect + 360
            }

          arrow.style.Transform = 'rotate(' + cDirect + 'deg)';
          arrow.style.WebkitTransform = 'rotate('+ cDirect + 'deg)';
          arrow.style.MozTransform = 'rotate(-' + cDirect + 'deg)';
      }

    }
  }
}
</script>
<style scoped>
#titleColor {
  color: #000000;
}

.cInst {
  font-size: 16px;
  font-weight: 500;
  color: #000000;
}

.outerContainer {
  height: 100%;
  width: 100%;
  text-align: center;
}

.parent {
  width: var(--width-height);
  height: var(--width-height);
  position: relative;
  top: 0;
  left: 0;
  border-style: solid;
  border-width: thin;
  display: inline-block;
  margin: 0 auto;
}
.image1 {
  position: relative;
  opacity: 0.7;
  top: 0;
  left: 0;
}
.image2 {
  position: absolute;
  top: 0px;
  left: 0px;
}

.diana {
  position: absolute;
  bottom: 0px;
  right: 0px;
  font-size: 12px;
  font-weight: 600;
}

a.dLink:link, a.dLink:visited {
  color: blue;
  text-decoration: none;
}

a.dLink:hover, a.dLink:active {
  color: blue;
  text-decoration: underline;
}

#arrow {
  visibility: hidden;
}

</style>
